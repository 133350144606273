
import Vue, { VNode } from "vue";
import { COMPANIES } from "@/constants/companies";
import { QUERY_TYPE } from "@/constants/query-type";
import { mapState, mapActions, mapGetters } from "vuex";
import { SearchRequest } from "../types/SearchRequest";
import axios from "axios";
import httpClient from "@/api/httpClient";

export default Vue.extend({
  name: "SourcesTable",
  data: () => ({
    loading: false,
    isTokenValid: false,
    error: null as string | null,
    headers: [
      { text: "ID", align: "left", value: "sourceId" },
      { text: "Nome da fonte", align: "left", value: "name" },
      { text: "País / Estado", align: "right", value: "locality" }
    ],
    types: [
      { typeCode: "news", type: "Online" },
      { typeCode: "flip", type: "Impresso" },
      { typeCode: "radio", type: "Rádio" },
      { typeCode: "tv", type: "TV" },
      { typeCode: "video", type: "Vídeo" }
    ],
    words: [
      { wordValue: "CONTAINS", word: "Contém" },
      { wordValue: "STARTS_WITH", word: "Inicia com" },
      { wordValue: "EXACT", word: "Exatamente" }
    ],
    typeCode: "tv",
    countryId: 0,
    stateId: 0,
    query: "",
    previousQuery: "",
    wordValue: "CONTAINS"
  }),

  async mounted() {
    await this.initialize();
  },

  computed: {
    ...mapState(["pagination", "sources", "countries", "states"]),

    ...mapGetters(["company"]),

    logo(): NodeRequire {
      return this.company === COMPANIES.CSERVICE
        ? require("@/assets/clipping_logo.png")
        : require("@/assets/knewin_logo.png");
    },
    disabled(): boolean {
      return this.countryId != 1;
    },

    pages(): number {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },

  methods: {
    ...mapActions([
      "fetchSources",
      "getCountries",
      "setPage",
      "setTotalItems"
    ]),

    async initialize() {
      const token = this.getTokenFromUrl();

      if (!token) {
        this.error = "não autorizado";
        return;
      }

      try {
        const isValid = await this.validateToken(token);

        if (isValid) {
          this.isTokenValid = true;
          await this.loadInitialData();
        } else {
          this.error = "Não autorizado. Acesse novamente a aplicação original.";
        }
      } catch (error) {
        this.error = "Não autorizado. Acesse novamente a aplicação original.";
      } finally {
        this.loading = false;
      }
    },

    getTokenFromUrl(): string | null {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("token");
    },

    async validateToken(token: string): Promise<boolean> {
      try {
        const response = await httpClient.get(`/api/validate-access`);
        return response.data.isValid;
      } catch (error) {
        throw new Error("Token validation failed");
      }
    },

    async loadInitialData() {
      try {
        this.loading = true;
        await this.getCountries(this.getTokenFromUrl());
        await this.searchSources(false, this.getTokenFromUrl());
      } catch (error) {
        this.error = "Erro ao carregar os dados iniciais";
      } finally {
        this.loading = false;
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async searchSources(changeType: boolean, token: any) {
      this.loading = true;

      const request: SearchRequest = {
        collection: null,
        query: null,
        ids: null,
        limit: null,
        offset: null,
        country: null,
        state: null
      };
      if (changeType) {
        this.setPage(1);
      }
      request.collection = this.typeCode === "flip" ? "news" : this.typeCode;
      if (this.countryId > 0) {
        request.country = this.countryId;
        if (this.countryId == 1 && this.stateId > 0) {
          request.state = this.stateId;
        }
      }

      if (this.previousQuery !== this.query) {
        this.previousQuery = this.query;
        this.setPage(1);
      }
      if (this.query) {
        switch (this.checkQueryType()) {
          case QUERY_TYPE.ID:
            request.ids = this.query;
            if ("flip" === this.typeCode) {
              request.query = "Flip";
            } else if ("news" === this.typeCode) {
              request.query = "!Flip";
            }
            break;
          default:
            request.query = this.filterByWord(this.query);
            if ("flip" === this.typeCode) {
              request.query = request.query.concat("|Flip");
            } else if ("news" === this.typeCode) {
              request.query = request.query.concat("|!Flip");
            }
        }
      } else if ("flip" === this.typeCode) {
        request.query = "Flip";
      } else if ("news" === this.typeCode) {
        request.query = "!Flip";
      }
      request.limit = this.pagination.rowsPerPage;
      request.offset = (this.pagination.page - 1) * this.pagination.rowsPerPage;

      await this.fetchSources(request);
      this.loading = false;
    },

    filterByWord(searchquery: string) {
      if (this.query.match(/https?:\/\/.+\..+/) === null) {
        switch (this.wordValue) {
          case "STARTS_WITH":
            searchquery = "^".concat(searchquery);
            break;
          case "EXACT":
            searchquery = "^".concat(searchquery).concat("$");
            break;
        }
      }
      return searchquery;
    },

    paginationChangeHandler(pageNumber: number) {
      this.pagination.page = pageNumber;
      this.searchSources(false, this.getTokenFromUrl());
    },

    checkQueryType() {
      if (Number.isNaN(Number(this.query))) {
        return QUERY_TYPE.NAME;
      } else {
        return QUERY_TYPE.ID;
      }
    },
    getTypes() {
      switch (this.company) {
        case COMPANIES.CSERVICE:
          this.typeCode = "news";
          return [this.types[0]];
        default:
          return this.types;
      }
    }
  },
  watch: {
    countryId: function (newQuestion, oldQuestion) {
      if (this.countryId === 0) {
        this.stateId = 0;
      }
    }
  }
});
